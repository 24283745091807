



























































































































import Vue from "vue";
import moment from "moment";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      userManager,
      items: [],
      totalItem: null,
      users: [],
      currentUser: null,
      currentDate: "",
      currentStatus: "dtl",
      loading: false,
      headers: [
        { text: this.$t("PACKAGE_ID"), value: "package_id" },
        { text: this.$t("PACKAGE_BATCH_ID"), value: "package_batch_id" },
        { text: this.$t("TRUCK_ID"), value: "truck_id" },
        { text: "Loại xe", value: "truck_type" },
        { text: "Tiền vận chuyển", value: "fee_transport" },
        { text: "Lợi nhuận thanh toán", value: "profit" },
        { text: "Lợi nhuận đơn", value: "package_profit" },
        { text: "Tổng lương DSVC", value: "salary_dsvc" },
        { text: "Tổng lương DV thanh toán", value: "salary_dvtt" },
        { text: "Phí ủy thác", value: "fee_trust" },
        { text: "Phí khác", value: "fee_other" },
        { text: "Ghi chú", value: "money_note" },
        { text: "Ngày về VN", value: "truck_vn_time" },
        { text: "Ngày thanh lý", value: "liquidation_time" },
      ],
      availableTruckTypeMap: {
        tap_13m: "Tạp 13m",
        tap_9m6: "Tạp 9m6",
        tap_17m: "Tạp 17m",
        tap_cont: "Cont tạp",
        tap_cont_20: "Cont tạp 20",
        tap_cont_40: "Cont tạp 40",
        tx: "Tách xe",
        nx: "Nguyên xe",
        nx_13m: "Nguyên xe 13m",
        nx_17m: "Nguyên xe 17m",
        nx_air: "Air",
        nx_cb: "Nguyên cont",
        nx_l: "Nguyên cont lẻ",
        db_cont_20: "Cont 20 đặc biệt",
        db_cont_40: "Cont 40 đặc biệt",
      },
      availableStatuses: [
        { value: "dtl", text: "Đã thanh lý" },
        { value: "ctl", text: "Chưa thanh lý" },
        { value: "hdv", text: "Hàng đã về" },
      ],
      timeModes: [
        {
          value: "month",
          text: "Tháng",
        },
        {
          value: "year",
          text: "Năm",
        },
      ],
      timeMode: "month",
    };
  },
  watch: {
    currentUser() {
      this.initialize();
    },
    currentDate() {
      this.initialize();
    },
    currentStatus() {
      this.initialize();
    },
    timeMode() {
      this.initialize();
    },
  },
  methods: {
    async initialize() {
      const self = this;
      if (!this.currentUser || !this.currentDate || !this.currentStatus) {
        return;
      }
      const m = moment(this.currentDate);
      this.loading = true;
      const options: any = {
        extra: {
          statistics: true,
          truck_statistics: true,
        },
        filters: [],
        report: true,
      };
      const liqudiationSlipOptions: any = {
        extra: {},
        filters: [],
        report: true,
      };

      if (this.currentUser.id) {
        options.auth_override = {
          id: this.currentUser.id,
          role: "kd",
          codes: this.currentUser.codes || [],
          ids: [],
        };
        liqudiationSlipOptions.extra.saleUserId = this.currentUser.id;
      }
      switch (this.currentStatus) {
        case "dtl":
          options.extra.liquidation_slip_approve_time = {
            from: m.startOf(this.timeMode).unix(),
            to: m.endOf(this.timeMode).unix(),
          };
          liqudiationSlipOptions.filters.push({
            key: "approve_time",
            operator: ">=",
            value: m.startOf(this.timeMode).unix(),
          });
          liqudiationSlipOptions.filters.push({
            key: "approve_time",
            operator: "<=",
            value: m.endOf(this.timeMode).unix(),
          });
          break;
        case "ctl":
          options.extra.truck_vn_time = {
            from: m.startOf(this.timeMode).unix(),
            to: m.endOf(this.timeMode).unix(),
          };
          options.extra.liquidation_slip_approved = false;
          liqudiationSlipOptions.filters.push({
            key: "created_at",
            operator: ">=",
            value: m.startOf(this.timeMode).format("Y-m-d"),
          });
          liqudiationSlipOptions.filters.push({
            key: "created_at",
            operator: ">=",
            value: m.endOf(this.timeMode).format("Y-m-d"),
          });
          break;
        case "hdv":
          options.extra.truck_vn_time = {
            from: m.startOf(this.timeMode).unix(),
            to: m.endOf(this.timeMode).unix(),
          };
          liqudiationSlipOptions.filters.push({
            key: "approve_time",
            operator: ">=",
            value: m.startOf(this.timeMode).unix(),
          });
          liqudiationSlipOptions.filters.push({
            key: "approve_time",
            operator: "<=",
            value: m.endOf(this.timeMode).unix(),
          });
          break;
      }
      let { items } = await apiClient.packageGetAll(options);
      const { items: liquidationSlips } = await apiClient.liquidationSlipGetAll(liqudiationSlipOptions);

      items = items.map((item) => {
        if (item.truck) {
          item.salary_dsvc =
            ((item.truck.type.startsWith("db") && 0.08) || (item.truck.type.startsWith("nx") && 0.02) || 0.04) *
            item.fee_transport;
        }
        item.salary_dvtt = item.profit * 0.2;
        if (item.truck && item.truck_total_volume) {
          item.cost = (item.volume / item.truck_total_volume) * item.truck.export_fee_total;
        } else {
          item.cost = 0;
        }

        item.package_profit = item.fee_transport + item.fee_trust + item.profit - item.cost;
        return item;
      });

      this.totalItem = items.reduce(
        (totalItem, item) => {
          totalItem.fee_transport += item.fee_transport || 0;
          totalItem.fee_trust += item.fee_trust || 0;
          totalItem.fee_other += item.fee_other || 0;
          totalItem.profit += item.profit || 0;
          totalItem.salary_dsvc += item.salary_dsvc || 0;
          totalItem.salary_dvtt += item.salary_dvtt || 0;
          totalItem.package_profit += item.package_profit || 0;
          return totalItem;
        },
        {
          fee_transport: 0,
          fee_trust: 0,
          fee_other: 0,
          profit: 0,
          salary_dsvc: 0,
          salary_dvtt: 0,
          package_profit: 0,
        }
      );
      this.totalItem.salary = this.totalItem.salary_dsvc + this.totalItem.salary_dvtt;

      this.totalItem.surplusage = 0;
      liquidationSlips.forEach((item) => {
        self.totalItem.surplusage += item.surplusage;
      });

      this.items = items;
      this.loading = false;
    },
  },
  async created() {
    this.currentDate = moment().format("YYYY-MM");
    // this.currentDate = "2021-11";
    if (userManager.checkRole(["kd"], true)) {
      const userInfo = userManager.getUserInfo();
      const user = {
        id: userInfo.sub,
        name: userInfo.name,
      };
      this.currentUser = user;
      this.users.push(user);
    } else {
      const { items } = await apiClient.userGetAll({
        limit: -1,
        // filters: [
        //   {
        //     key: "roles",
        //     operator: "equal_to",
        //     value: "kd",
        //   },
        // ],
      });
      items.unshift({
        name: "ALL",
      });
      for (const user of items) {
        if (
          !user.roles ||
          (!user.roles.includes("kd") && !user.roles.includes("tpkd") && !user.roles.includes("gdkd"))
        ) {
          continue;
        }
        this.users.push(user);
      }
    }
  },
});
